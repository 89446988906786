<rd-header appName="Care Gaps and Inequities" [showActions]="true">
  <div class="content" rd-header-content>
    <div>
      <rd-select
        [mode]="'default'"
        [isKeyLabelOptions]="false"
        [isBorderless]="true"
        [isLoading]="false"
        [options]="gastroDemoSubTherapeuticArea.items"
        [selectedItems]="gastroDemoSubTherapeuticArea.selectedValue"
        (selectedItemsChange)="onSubTherapyChange($event)"
      >
      </rd-select>
    </div>
    <div class="info">
      <rd-icon
        [type]="'custom:graphIcon'"
        [iconStyle]="{ cursor: 'pointer' }"
        (click)="onGraphIconClick()"
      ></rd-icon>
    </div>
  </div>
</rd-header>

<rd-modal
  [isVisible]="isDataSourceModalVisible"
  (modalCloseEvent)="isDataSourceModalVisible = false"
>
  <div rdModalTitle>Data Snapshot</div>
  <div rdModalContent>
    <rd-table
      [showPagination]="false"
      [tableColumns]="dataSource.tableColumns"
      [isLoading]="dataSource.isLoading"
      [tableData]="dataSource.tableData"
    >
    </rd-table>
  </div>
</rd-modal>
