import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() tabs: any;
  
  @Output() menuChanged: EventEmitter<any> = new EventEmitter();
  
  @Output() menuClick: EventEmitter<any> = new EventEmitter();
  
  public subscription: Subscription = new Subscription();
  
  public activeItem: string = null;
  
  constructor(
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.activeItem = this.tabs?.[0]?.path;

    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
            this.activeItem = event?.urlAfterRedirects?.includes('?') ? event?.urlAfterRedirects?.split('?')[0] : event?.urlAfterRedirects;
      });
  }

  menuItemClick(tab, event) {
    this.activeItem = tab?.path;
  }

}
