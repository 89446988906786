import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  RDAlertModule,
  RDButtonModule,
  RDHeaderModule,
  RDIconModule,
  RDModalModule,
  RDNotificationModule,
  RDRadioModule,
  RDSiderModule,
  RDSpinModule,
  RDTileModule,
  RDSelectModule,
  RDTableModule,
} from '@zs-ca/angular-cd-library';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SiderComponent } from './layout/sider/sider/sider.component';
import { HeaderComponent } from './layout/header/header.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor, AuthInterceptor } from './core/interceptors';
import { CustomIconService } from './core/services/custom-icon.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { ToasterModule } from '../app/shared/components/toaster/toaster.module';
import { MenuComponent } from './layout/menu/menu.component';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [AppComponent, SiderComponent, HeaderComponent, MenuComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RDButtonModule,
    RDSiderModule,
    RDSelectModule,
    RDHeaderModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RDAlertModule,
    RDModalModule,
    RDNotificationModule,
    RDSpinModule,
    RDIconModule,
    RDTileModule,
    RDModalModule,
    RDRadioModule,
    RDTableModule,
    BrowserAnimationsModule,
    SharedModule,
    ToasterModule,
    RouterModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    CustomIconService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
