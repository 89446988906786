import { Component, Input } from '@angular/core';
import { IDeeplinkConfig } from './deeplink.model';
import { CareGapService } from 'src/app/core/services/care-gap.service';

@Component({
  selector: 'app-deeplink-modal-popup',
  templateUrl: './deeplink-modal-popup.component.html',
  styleUrls: ['./deeplink-modal-popup.component.scss']
})
export class DeeplinkModalPopupComponent {
  public resourcesText: string = 'Resources';

  @Input() deeplinkConfig: Array<IDeeplinkConfig>;

  constructor(
    public careGapService: CareGapService
  ){}
}
