<app-sider *ngIf="!isEmbeddedIntoDataFrame"
  [menuItemsList]="sideBarMenuItemsList"
  [siderData]="launchBarData"
></app-sider>
<div class="app-layout" [ngClass]="!isEmbeddedIntoDataFrame ? 'with-sidebar' : 'without-sidebar'">
  <app-header></app-header>
  <app-menu [tabs]="navMenuItemList"></app-menu>
  <ng-container *ngIf="!isLoading; else loadingSpinner">
    <div class="app-content" *ngIf="!errorMessage">
      <router-outlet></router-outlet>
    </div>
    <app-deeplink-modal-popup *ngIf="careGapService.isDeeplinkModalVisible"  [deeplinkConfig]="careGapService.deeplinkLinks"></app-deeplink-modal-popup>
    <div class="place-items-center-absolute" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
  </ng-container>
</div>

<ng-template #loadingSpinner>
  <div class="place-items-center-absolute">
    <rd-spin
      [spinnerStyle]="{
        'font-size': '40px',
        color: '#27a6a4',
        position: 'relative',
        right: '40px'
      }"
    ></rd-spin>
  </div>
</ng-template>
<app-toaster></app-toaster>
