import { Component } from '@angular/core';
import { CustomIconService } from 'src/app/core/services/custom-icon.service';
import { CareGapService } from 'src/app/core/services/care-gap.service';
import { monthMapping } from 'src/app/core/models/constant.model';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  gastroDemoSubTherapeuticArea = {
    selectedValue: 'Gastroenterology / Gastroesophageal Reflux Disease',
    items: ['Gastroenterology / Gastroesophageal Reflux Disease'],
    originalData: [] as any[],
    isLoading: false,
  };

  subTherapeuticArea = {
    selectedValue: 'NSCLC',
    items: [] as any[],
    originalData: [] as any[],
    isLoading: false,
  };

  isDataSourceModalVisible = false;

  dataSource = {
    tableColumns: [
      {
        key: 'data_source',
        name: 'Data Source',
        width: '20%',
        sortable: true,
        dataType: 'string',
        defaultSortOrder: '',
      },
      {
        key: 'description',
        name: 'Information',
        width: '20%',
        sortable: true,
        dataType: 'string',
        defaultSortOrder: '',
      },
      {
        key: 'time_period',
        name: 'Data Time Period',
        width: '25%',
        sortable: true,
        dataType: 'string',
        defaultSortOrder: 'desc',
        sortHandler: (order: string) => this.customSortFunction(order),
      },
    ],
    tableData: [],
    isLoading: false,
  };

  start_dt: any;
  end_dt: any;

  constructor(
    private customIconService: CustomIconService,
    private careGapService: CareGapService
  ) {}

  ngOnInit() {
    this.careGapService.areCareGapFiltersLoaded$.next(false);
    this.getSubTherapeuticDropdown();
    // this.getDataSourceDetails();
  }

  getSubTherapeuticDropdown() {
    this.subTherapeuticArea.isLoading = true;
    const payload = {
      data_source: '',
      therapeutic_area: '',
    };
    this.careGapService.getSubTherapeuticArea(payload).subscribe({
      next: (res) => {
        this.subTherapeuticArea.isLoading = false;
        this.subTherapeuticArea.originalData = [...res];
        this.transformSubTherapy(res);
      },
      error: (error) => {
        this.subTherapeuticArea.isLoading = false;
      },
    });
  }

  transformSubTherapy(data) {
    this.careGapService.provider_oppr_flag = data[0].provider_oppr_flag;

    data.map((list) => {
      this.subTherapeuticArea.items.push(
        list['sub_therapeutic_area_display_name']
      );
    });
    this.subTherapeuticArea.selectedValue = this.subTherapeuticArea.items[0];
    let selectedSubTherapeuticArea = this.subTherapeuticArea.originalData.find(
      (item) =>
        item.sub_therapeutic_area_display_name ===
        this.subTherapeuticArea.selectedValue
    )['sub_therapeutic_area'];
    this.careGapService.selectedSubTherapy$.next(selectedSubTherapeuticArea);
    this.careGapService.areCareGapFiltersLoaded$.next(false);
  }

  onSubTherapyChange(event: string) {
    this.subTherapeuticArea.selectedValue = event;
    this.careGapService.areCareGapFiltersLoaded$.next(false);
    let selectedSubTherapeuticArea = this.subTherapeuticArea.originalData.find(
      (item) =>
        item.sub_therapeutic_area_display_name ===
        this.subTherapeuticArea.selectedValue
    )['sub_therapeutic_area'];
    this.careGapService.selectedSubTherapy$.next(selectedSubTherapeuticArea);
  }

  getDataSourceDetails() {
    const payload = {
      data_source: '',
      therapeutic_area: '',
      sub_therapeutic_area: '',
    };
    this.dataSource.isLoading = true;
    this.careGapService.getDateRange(payload).subscribe({
      next: (res) => {
        // TO-DO: remove the static data after api integration
        this.dataSource.tableData = res.data_source_list;
        this.transformData();
        this.dataSource.isLoading = false;
      },
      error: (error) => {
        this.dataSource.isLoading = false;
      },
    });
  }

  transformData() {
    this.dataSource.tableData.map((row) => {
      if (row['data_start_dt'] == 'None') {
        const index = row['data_end_dt'].lastIndexOf('/');
        row['time_period'] =
          'as of ' + row['data_end_dt'].substring(index + 1, index + 5);
      } else {
        row['time_period'] =
          this.getDateFormat(row['data_start_dt']) +
          ' - ' +
          this.getDateFormat(row['data_end_dt']);
      }
    });
  }

  getDateFormat(value: any) {
    let first_index = value.indexOf('/');
    let last_index = value.lastIndexOf('/');
    let month = value.substring(0, first_index);
    let month_value = monthMapping[month];
    let year_value = value.substring(last_index + 1, last_index + 5);
    return month_value + "'" + year_value;
  }

  customSortFunction = (order: string) => (a: any, b: any) =>
    order === 'asc'
      ? new Date(a['data_end_dt']).getTime() -
        new Date(b['data_end_dt']).getTime()
      : new Date(b['data_end_dt']).getTime() -
        new Date(a['data_end_dt']).getTime();

  onGraphIconClick() {
    this.isDataSourceModalVisible = true;
    this.getDataSourceDetails();
  }
}
