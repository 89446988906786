<div class="menu-list">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <div (mouseleave)="tab.isHovered = false">
      <div class="menu-item" (click)="menuItemClick(tab, $event)" (mouseover)="tab.isHovered = true">
        <div [class.menu-item-title]="tab.path !== activeItem" [class.active]="tab.path === activeItem" [routerLink]="tab.path">
          <div class="title">{{tab?.title}}</div>
        </div>
        <rd-icon *ngIf="tab?.children" [type]="'down'" [iconStyle]="{ 'margin': '4px 4px 0px 4px', 'font-size': '14px', 'color': '#27a6a4' }"></rd-icon>
      </div>
      <div class="child-menu-item" *ngIf="tab?.children?.length && tab?.isHovered">
        <ng-container *ngFor="let child of tab?.children">
          <ng-container *ngIf="child?.path">
            <div class="child-menu-item-title" [routerLink]="child.path" *ngIf="!child?.disabled"><div class="title">{{child?.title}}</div></div>
            <div *ngIf="child?.disabled">{{child?.title}}</div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
