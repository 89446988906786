

<rd-modal
  modalWidth="50%"
  [isMaskClosable]="false"
  [isVisible]="careGapService.isDeeplinkModalVisible"
  (cancelEvent)="careGapService.isDeeplinkModalVisible = false"
>
  <div rdModalTitle>{{careGapService?.hcpToBeDeeplinked}}: {{resourcesText}}</div>
  <div rdModalContent>
    <div class="small-description">Jump to other apps to learn more about this provider.</div>
    <div class="deeplink-links">
        <ul *ngFor="let deeplink of deeplinkConfig">
            <li class="deeplink-item">
              <span><a class="deeplink-link" [href]="deeplink.link">{{ deeplink.title }}</a></span>
            </li>
        </ul>
    </div>
  </div>
  <div rdModalFooter>
  </div>
</rd-modal>
