import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CareGapFiltersComponent } from './components/care-gap-filters/care-gap-filters.component';
import { SavedLocationsComponent } from './components/saved-locations/saved-locations.component';
import { CascaderDropdownComponent } from './components/cascader-dropdown/cascader-dropdown.component';
import {
  RDAlertModule,
  RDButtonModule,
  RDHeaderModule,
  RDIconModule,
  RDModalModule,
  RDNotificationModule,
  RDRadioModule,
  RDSiderModule,
  RDSpinModule,
  RDTileModule,
  RDSelectModule,
  RDTagModule,
  RDTableModule,
  RDSkeletonModule,
  RDDrawerModule,
  RDTooltipModule,
  RDCollapseModule,
} from '@zs-ca/angular-cd-library';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormsModule } from '@angular/forms';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { GeoMapComponent } from './components/geo-map/geo-map.component';
import { AccountFlyoutComponent } from './components/account-flyout/account-flyout.component';
import { HcpFlyoutComponent } from './components/hcp-flyout/hcp-flyout.component';
import { StackedBarChartComponent } from './components/stacked-bar-chart/stacked-bar-chart.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { ZipHeatmapComponent } from './components/zip-heatmap/zip-heatmap.component';
import { StateHeatmapComponent } from './components/state-heatmap/state-heatmap.component';
import { StateGoogleMapComponent } from './components/state-google-map/state-google-map.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { HighlighterPipe } from './pipes/highlighter.pipe';
import { DeeplinkModalPopupComponent } from './components/deeplink-modal-popup/deeplink-modal-popup.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RDAlertModule,
    RDButtonModule,
    RDHeaderModule,
    RDTagModule,
    RDIconModule,
    RDModalModule,
    RDNotificationModule,
    RDRadioModule,
    RDSiderModule,
    RDSpinModule,
    RDTileModule,
    RDTableModule,
    RDSelectModule,
    NzSelectModule,
    NzCheckboxModule,
    NzDividerModule,
    RDSkeletonModule,
    RDTooltipModule,
    RDCollapseModule,
    RDDrawerModule,
    NzDropDownModule,
    NzIconModule,
    NzTreeModule,
    NzInputModule,
    NzTreeSelectModule,
    DragScrollModule,
  ],
  declarations: [
    CareGapFiltersComponent,
    CascaderDropdownComponent,
    SavedLocationsComponent,
    GeoMapComponent,
    AccountFlyoutComponent,
    HcpFlyoutComponent,
    StackedBarChartComponent,
    BarChartComponent,
    StateHeatmapComponent,
    ZipHeatmapComponent,
    StateGoogleMapComponent,
    HighlighterPipe,
    DeeplinkModalPopupComponent,
  ],
  exports: [
    CareGapFiltersComponent,
    CascaderDropdownComponent,
    SavedLocationsComponent,
    GeoMapComponent,
    AccountFlyoutComponent,
    HcpFlyoutComponent,
    StackedBarChartComponent,
    BarChartComponent,
    StateHeatmapComponent,
    ZipHeatmapComponent,
    StateGoogleMapComponent,
    HighlighterPipe,
    DeeplinkModalPopupComponent
  ],
})
export class SharedModule {}
